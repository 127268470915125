<template>
  <b-row>

    <b-col cols="12">
      <b-form-group>
        <v-select v-if="categories != null" v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name" :options="categories" class="select-size-lg" single>
          <template #option="{ name }">
            <span> {{ name }}</span> 
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created() {
    this.fetchDepartments()

  },
  props: {
    initialCategories: null
  },


  methods: {
    updateSelections(a) {

      //console.log(a)
    },
    fetchDepartments() {
      //console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('admin/f/clientdepartments')
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            //console.log(responseData.data);
            this.categories = responseData.data;
            if (this.initialCategories == null || this.initialCategories.length == 0) {
              // this.selected=this.categories[0];
            }
          }
          )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    selected: function (newVal, coldVal) {
    
      this.$emit("selectionChanged", newVal);
      this.selected = newVal
    }
  },
  data() {
    return {
      categories: null,
      selected: this.initialCategories,
      option: [],
    }
  }


}
</script>