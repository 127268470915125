import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import ExcelJs from "exceljs";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
    const toast = useToast();
    const selectedFile = ref(null);
    const registerUsers = (userList) => {
        store
            .dispatch("apps-members/importUserList", userList)
            .then((response) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Success!",
                        icon: "CoffeeIcon",
                        variant: "success",
                    },
                });
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong while importing user list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };
    const readExcel = async (file) => {
        const userList = [];

        const wb = new ExcelJs.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then((workbook) => {
                console.log(workbook, "workbook instance");
                workbook.eachSheet((sheet, id) => {
                    let i = 0; // header index of excel
                    sheet.eachRow((row, rowIndex) => {
                        console.log(rowIndex);
                        if (i > 0) {
                            userList.push({
                                firstname: row.values[2],
                                lastname: row.values[3],
                                fullname: `${row.values[2]} ${row.values[3]}`,
                                password: row.values[5],
                                email: row.values[4],
                                department: row.values[6],
                            });
                        }
                        i += 1;
                    });
                });
                console.log(userList);
                registerUsers(userList);
            });
        };
    };
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            await readExcel(file);
        }
    };

    return {
        selectedFile,
        handleFileChange,
    };
}
